import React, { FC, Fragment } from "react"
import {
  Paper,
  Typography,
  Link as MuiLink,
  Divider,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"
import { useStaticQuery, graphql } from "gatsby"
import { FavoriteBorderOutlined } from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  footer: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    padding: theme.spacing(3, 0),
  },
  divider: {
    width: "50%",
    margin: `${theme.spacing(1)}px auto`,
  },
  mainColor: {
    color: theme.palette.primary.dark,
  },
}))

export const Footer: FC = () => {
  const data = useStaticQuery(graphql`
    query FooterItemQuery {
      dataJson {
        email
        haulageRegisterNumber
        phoneNumbers {
          label
          value
        }
        registeredOffice
        vat
        pec
        operationalHeadquarters
      }
    }
  `)
  const contacts = data.dataJson

  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const variant = isDesktop ? "body1" : "body2"
  return (
    <Paper component="footer" className={classes.footer}>
      <Typography component="span" variant={variant}>
        <Typography
          variant={variant}
          component="span"
          className={classes.mainColor}
        >
          Sede Legale&nbsp;
        </Typography>
        {contacts.registeredOffice}
        {isDesktop ? <>&nbsp;-&nbsp;</> : <br />}
        <Typography
          variant={variant}
          component="span"
          className={classes.mainColor}
        >
          Sede operativa&nbsp;
        </Typography>
        {contacts.operationalHeadquarters}
        <br />
        <Typography
          variant={variant}
          component="span"
          className={classes.mainColor}
        >
          Telefono&nbsp;
        </Typography>
        {contacts.phoneNumbers.map(({ value, label }, i) => (
          <Fragment key={i}>
            <MuiLink
              href={`tel:${value}`}
              target="_blank"
              rel="nofollow"
              color="textSecondary"
            >
              {label}
            </MuiLink>{" "}
            {i + 1 !== contacts.phoneNumbers.length && <>-&nbsp;</>}
          </Fragment>
        ))}
        {isDesktop ? <>&nbsp;-&nbsp;</> : <br />}
        <Typography
          variant={variant}
          component="span"
          className={classes.mainColor}
        >
          Email&nbsp;
        </Typography>
        <MuiLink
          href={`mailto:${contacts.email}`}
          target="_blank"
          rel="nofollow"
          color="textSecondary"
        >
          {contacts.email}
        </MuiLink>
        {isDesktop ? <>&nbsp;-&nbsp;</> : <br />}
        <Typography
          variant={variant}
          component="span"
          className={classes.mainColor}
        >
          PEC&nbsp;
        </Typography>
        {contacts.pec}
        <br />
        <Typography
          variant={variant}
          component="span"
          className={classes.mainColor}
        >
          P. IVA&nbsp;
        </Typography>
        {contacts.vat}
        {isDesktop ? <>&nbsp;-&nbsp;</> : <br />}
        <Typography
          variant={variant}
          component="span"
          className={classes.mainColor}
        >
          Albo autotrasportatori&nbsp;
        </Typography>
        {contacts.haulageRegisterNumber}
        <Divider className={classes.divider} />
        Privacy - © {new Date().getFullYear()}
        <br />
        Built with <FavoriteBorderOutlined fontSize="small" />
      </Typography>
    </Paper>
  )
}
