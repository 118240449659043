import React, { FC } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useTheme, useMediaQuery } from "@material-ui/core"

export const LogoImg: FC<{ wrapperClassName?: string }> = ({
  wrapperClassName,
}) => {
  const theme = useTheme()
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktopImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const sources = [
    data.desktopImage.childImageSharp.fluid,
    {
      ...data.mobileImage.childImageSharp.fluid,
      media: theme.breakpoints.down("sm"),
    },
  ]

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div className={wrapperClassName}>
      <Img
        durationFadeIn={50}
        title="vezzaro snc quick food delivery logo"
        alt="vezzaro-snc-logo"
        fluid={sources}
        style={{
          maxHeight: isMobile ? 60 : 80,
          maxWidth: isMobile ? 300 : 400,
        }}
      />
    </div>
  )
}
