import { Link, useStaticQuery, graphql } from "gatsby"
import {
  AppBar,
  Toolbar,
  makeStyles,
  Divider,
  MenuList,
  MenuItem,
  Typography,
  Link as MuiLink,
  Drawer,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@material-ui/core"
import clsx from "clsx"
import { ChevronRight, Menu } from "@material-ui/icons"
import React, { Fragment, useState, FC, useRef } from "react"
import { LogoImg } from "./logo"
// import { FbLogo } from "./facebookLogo"

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  toolBar: {
    display: "flex",
    alignItems: "inherit",
    justifyContent: "space-between",
  },
  logoWrapper: {
    margin: "auto 0",
    [theme.breakpoints.up("lg")]: {
      flexBasis: "20%",
    },
    [theme.breakpoints.between("md", "lg")]: {
      flexBasis: "40%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      flexBasis: "45%",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      flexBasis: "60%",
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1, 0),
    },
  },
}))

const useDesktopStyles = makeStyles(theme => ({
  menuWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  menuList: {
    display: "flex",
    justifyContent: "flex-end",
    "&:focus": {
      outline: "none",
    },
  },
  menuListItem: {
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0, 1),
    },
  },
  menuContacts: {
    display: "flex",
    justifyContent: "flex-end",
  },
  fblogo: {
    margin: theme.spacing(0, 1),
  },
}))

const useMobileStyles = makeStyles(theme => ({
  menuList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    "&:focus": {
      outline: "none",
    },
  },
  menuListItem: {
    padding: theme.spacing(2, 4),
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  hide: {
    display: "none",
  },
}))

const Header = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query MenuItemQuery {
      dataJson {
        email
        haulageRegisterNumber
        phoneNumbers {
          label
          value
        }
        registeredOffice
        vat
        pec
        operationalHeadquarters
      }
      allMenuJson {
        edges {
          node {
            label
            path
          }
        }
      }
    }
  `)

  const menu = data.allMenuJson.edges
  const contacts = data.dataJson
  const appBarRef = useRef<HTMLDivElement>(null)

  const onMenuVoiceCLick = (to: string) => (
    e: React.MouseEvent<HTMLLIElement>
  ) => {
    e.preventDefault()
    if (appBarRef.current) {
      const elem = document.getElementById(to.replace(/\/?#/, ""))
      if (elem) {
        window.scrollTo({
          left: 0,
          top:
            elem.offsetTop - appBarRef.current.getBoundingClientRect().bottom,
          behavior: "smooth",
        })
      }
    }
  }

  return (
    <AppBar ref={appBarRef} position="sticky" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <LogoImg wrapperClassName={classes.logoWrapper} />
        {isDesktop ? (
          <DesktopMenu
            menu={menu}
            contacts={contacts}
            onMenuVoiceCLick={onMenuVoiceCLick}
          />
        ) : (
          <MobileMenu menu={menu} onMenuVoiceCLick={onMenuVoiceCLick} />
        )}
      </Toolbar>
    </AppBar>
  )
}

interface IMenuProps {
  menu: {
    node: {
      label: string
      path: string
    }
  }[]
  onMenuVoiceCLick: (to: string) => (e: React.MouseEvent<HTMLLIElement>) => void
}

interface IDesktopMenuProps extends IMenuProps {
  contacts: {
    email: string
    haulageRegisterNumber: string
    phoneNumbers: {
      value: string
      label: string
    }[]
    registeredOffice: string
    vat: string
    pec: string
    operationalHeadquarters: string
  }
}

const DesktopMenu: FC<IDesktopMenuProps> = ({
  contacts,
  menu,
  onMenuVoiceCLick,
}) => {
  const classes = useDesktopStyles()
  return (
    <div className={classes.menuWrapper}>
      <div className={classes.menuContacts}>
        <Typography variant="button" color="textSecondary">
          {contacts.phoneNumbers.map(({ value, label }, i) => (
            <Fragment key={i}>
              <MuiLink
                href={`tel:${value}`}
                target="_blank"
                rel="nofollow"
                color="textSecondary"
              >
                {label}
              </MuiLink>{" "}
              -&nbsp;
            </Fragment>
          ))}
          <MuiLink
            href={`mailto:${contacts.email}`}
            target="_blank"
            rel="nofollow"
            color="textSecondary"
            style={{ textTransform: "lowercase" }}
          >
            {contacts.email}
          </MuiLink>
        </Typography>
        {/* <FbLogo className={classes.fblogo} /> */}
      </div>
      <Divider />
      <MenuList className={classes.menuList}>
        {menu.map(({ node }) =>
          node.path.startsWith("#") || node.path.startsWith("/#") ? (
            <MenuItem
              key={`menuVoice_${node.label}`}
              onClick={onMenuVoiceCLick(node.path)}
              className={classes.menuListItem}
            >
              <Typography variant="button" color="textPrimary">
                {node.label}
              </Typography>
            </MenuItem>
          ) : (
            <MenuItem
              key={`menuVoice_${node.label}`}
              component={Link}
              to={node.path}
              className={classes.menuListItem}
            >
              <Typography variant="button" color="textPrimary">
                {node.label}
              </Typography>
            </MenuItem>
          )
        )}
      </MenuList>
    </div>
  )
}

const MobileMenu: FC<IMenuProps> = ({ menu, onMenuVoiceCLick }) => {
  const [open, setOpen] = useState(false)
  const classes = useMobileStyles()
  const handleDrawerClose = () => setOpen(false)
  const handleDrawerOpen = () => setOpen(true)
  return (
    <>
      <IconButton
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerOpen}
        className={clsx(open && classes.hide)}
      >
        <Menu />
      </IconButton>
      <Drawer anchor="right" open={open}>
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronRight />
          </IconButton>
        </div>
        <Divider />
        <MenuList className={classes.menuList}>
          {menu.map(({ node }) => [
            node.path.startsWith("#") || node.path.startsWith("/#") ? (
              <MenuItem
                key={`menuVoice_${node.label}`}
                onClick={onMenuVoiceCLick(node.path)}
                className={classes.menuListItem}
              >
                <Typography variant="button" color="textPrimary">
                  {node.label}
                </Typography>
              </MenuItem>
            ) : (
              <MenuItem
                key={`menuVoice_${node.label}`}
                component={Link}
                to={node.path}
                className={classes.menuListItem}
              >
                <Typography variant="button" color="textPrimary">
                  {node.label}
                </Typography>
              </MenuItem>
            ),
            <Divider variant="middle" />,
          ])}
        </MenuList>
      </Drawer>
    </>
  )
}

export default Header
