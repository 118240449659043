import { Button, Snackbar, IconButton, useTheme } from "@material-ui/core"
import { CloseOutlined } from "@material-ui/icons"
import React, { useEffect, FC, useState } from "react"

export const SiteUpdated: FC = () => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()

  const onUpdateReady = () => setOpen(true)
  const handleClose = (
    _: React.SyntheticEvent | MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return
    }
    setOpen(false)
  }

  const handleExited = () => setOpen(false)
  const onUpdateClick = () => window.location.reload(true)

  useEffect(() => {
    window.addEventListener("update-ready", onUpdateReady)
    return () => window.removeEventListener("update-ready", onUpdateReady)
  }, [])

  return (
    <Snackbar
      key="update-ready"
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={10000}
      onClose={handleClose}
      onExited={handleExited}
      message={`Il sito è stato aggiornato, fai click su "AGGIORNA" o ricarica la pagina per visualizzare la nuova versione`}
      action={
        <React.Fragment>
          <Button color="secondary" onClick={onUpdateClick}>
            AGGIORNA
          </Button>
          <IconButton
            aria-label="close"
            color="inherit"
            style={{ padding: theme.spacing(0.5) }}
            onClick={handleClose}
          >
            <CloseOutlined />
          </IconButton>
        </React.Fragment>
      }
    />
  )
}
