import React, { FC, ReactNode } from "react"
import Header from "./header"
import "./layout.css"
import { Footer } from "./footer"
import { makeStyles } from "@material-ui/core"
import { SiteUpdated } from "./siteUpdated"

const useStyles = makeStyles(theme => ({
  main: {
    backgroundColor: "#F2F2F2",
    display: "flex",
    flexDirection: "column",
  },
}))

interface ILayoutProps {
  children: ReactNode
}

export const Layout: FC<ILayoutProps> = ({ children }) => {
  const classes = useStyles()
  return (
    <>
      <SiteUpdated />
      <Header />
      <main className={classes.main}>{children}</main>
      <Footer />
    </>
  )
}
